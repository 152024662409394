<template>
	<a-tree-select
		v-model:value="code"
		show-search
		style="width: 100%"
		:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
		placeholder="Please select"
		allow-clear
		:field-names="{
			children: 'childrenList',
			label: 'name',
			value: 'id',
		}"
		:max-tag-count="5"
		tree-checkable
		multiple
		tree-default-expand-all
		:tree-data="treeData"
		@change="handleChange"
		tree-node-filter-prop="label"
	>
		<!-- <template #title="{ value: val, label }">
			<b v-if="val === 'parent 1-1'" style="color: #08c">{{ val }}</b>
			<template v-else>{{ label }}</template>
		</template> -->
	</a-tree-select>
</template>
<script setup>
import { defineProps, defineExpose, ref, onMounted, defineEmits } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
// import { useI18n } from "vue-i18n/index";
const props = defineProps({
	menuIdList: {
		type: Array,
		default: null,
	},
	authSysCode: {
		type: String,
		default: "",
	},
})

const emits = defineEmits(["change"])

const code = ref([])
const store = useStore()
const options = ref([])
// watch(
// 	() => props.menuIdList,
// 	(value) => {
// 		console.log("menuIdList.", value)
// 		code.value = value.map((i) => String(i))
// 		console.log(code.value)
// 	}
// )
// watch(code.value, (value) => {
// 	console.log("select", value)
// 	// emits("update:appCode", value)
// })

onMounted(() => {
	treeData.value = []
	getList("init")
})
const treeData = ref([])
const addKey = (arr) => {
	if (arr.length > 0) {
		arr.forEach((i) => {
			i.label = i.name
			i.id = String(i.id)
			i.value = String(i.id)
			i.key = String(i.id)
			if (i.childrenList.length > 0) {
				//用来最后比对 属于哪个父节点
				i.children = i.childrenList.map((t) => String(t.id))
				addKey(i.childrenList)
			}
		})
	}
}

const getList = (value) => {
	options.value = []
	const data = {
		merchantId: store.getters["user/merchantId"],
		authSysCode: props.authSysCode,
		name: "",
		status: true,
		all: true, //是否需要全部权限
		resourceTypes: ["MENU"], //资源类型 MENU菜单 FUNC功能
		tree: true, //是否需要树形结构
		userId: "",
	}
	axios.post(api.resourceList, data).then((res) => {
		console.log("menuTree", res)
		addKey(res)
		setTimeout(() => {
			treeData.value = res
			if (value == "init") {
				console.log(code.value)
				code.value = props.menuIdList.map((i) => String(i))
				for (let i = 0; i < code.value.length; i++) {
					for (let j = 0; j < treeData.value.length; j++) {
						if (code.value[i] == treeData.value[j].id) {
							code.value.splice(i, 1)
							i--
						}
					}
				}
				console.log(code.value)
			}
		})
	})
}
const finalArr = ref([])
const handleChange = (value, label, extra) => {
	console.log(value, label, extra)
	console.log()
	finalArr.value = JSON.parse(JSON.stringify(value))
	value.forEach((item) => {
		treeData.value.forEach((i) => {
			if (i.children.indexOf(item) > -1) {
				finalArr.value.push(i.id)
			}
		})
	})
	finalArr.value = [...new Set(finalArr.value)]
	console.log("finalArr", finalArr.value)
	emits("change", finalArr.value)
}
const clear = () => {}
defineExpose({
	clear,
})
</script>
