<template>
	<a-select
		v-model:value="selectValue"
		allow-clear
		:class="props.class ? '' : 'inputSearch'"
		show-search
		mode="multiple"
		placeholder="请选择商户"
		:default-active-first-option="false"
		:show-arrow="false"
		:not-found-content="fetching ? undefined : null"
		:options="options"
		:filter-option="filterOption"
		@focus="getList"
		@change="handleChange"
	>
		<template v-if="fetching" #notFoundContent>
			<a-spin size="small" />
		</template>
	</a-select>
</template>
<script setup>
import { ref, watch, defineProps, defineEmits, nextTick } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"

const props = defineProps({
	diasbled: Boolean,
	class: {
		type: String,
		default: "",
	},
	list: {
		type: Array,
		default: () => [],
	},
})

const emits = defineEmits(["changeValue", "update:list"])
const selectValue = ref([])
const options = ref([])
const fetching = ref(false)

const handleChange = (value) => {
	console.log("value: ", value)
	emits("update:list", value)
	emits("changeValue", value)
}
const getList = () => {
	if (options.value.length) {
		return
	}
	let data = {
		page_no: 1,
		page_size: 500,
		status: 1,
	}
	fetching.value = true
	axios.post(api.getMerchantList, data).then((res) => {
		if (res.list?.length) {
			let arr = []
			res.list.forEach((item) => {
				arr.push({
					label: item.name,
					value: String(item.id),
				})
			})
			options.value = arr
		} else {
			options.value = []
		}
		if (props.list) {
			selectValue.value = props.list.map((item) => String(item))
		}
		fetching.value = false
	})
}
const filterOption = (input, option) => {
	return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

watch(
	() => props.list,
	() => {
		nextTick(() => {
			getList()
		})
	},
	{
		immediate: true,
	}
)
</script>
