<template>
	<a-card class="guest-container">
		<template #title>
			<a-alert message="权限系统" type="info" show-icon closable v-show="authAlert === 'open'" @close="closeAlert" style="margin-bottom: 10px">
				<template #icon><bulb-outlined style="margin-top: 20px" /></template>
				<template #description>
					<div class="alertContent">
						权限系统是基于RBAC（Role-Based Access Control，基于角色的访问控制）的权限模型。
						<br />
						既可以管理 IDaaS 自身资源权限，也可以管理第三方系统的二级菜单功能按钮等资源权限；当授予某个 IDaaS
						用户开发者权限，在未来将可以创建出新的自定义权限系统，以支持系统的三级授权。
						<br />
						默认系统只能关联开发者角色，其它功能不可用；自建系统可以正常使用，用于集中管理第三方系统的权限。
					</div>
				</template>
			</a-alert>
			<div class="header">
				<a-button type="primary" v-if="isDeveloper" @click="handleAdd">新增系统</a-button>
			</div>
		</template>

		<a-table :loading="loading" :columns="columns" rowKey="id" :scroll="{ y: tableHeight }" :data-source="tableData" :pagination="false">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'merchantAppVOList'">
					<span>
						{{ record.merchantAppVOList && record.merchantAppVOList.length > 0 ? record.merchantAppVOList[0].name : "" }}
					</span>
				</template>
				<template v-if="column.key === 'status'">
					<span>
						<a-switch v-model:checked="record.status" />
					</span>
				</template>
				<template v-if="column.key === 'action'">
					<span>
						<a @click="managePage(record, 'oauthDetail')" style="margin-right: 20px"> 授权管理 </a>
						<a @click="managePage(record, 'roleManage')" style="margin-right: 20px"> 角色管理 </a>
						<a @click="managePage(record, 'resource')" style="margin-right: 20px" v-if="isDeveloper"> 资源管理 </a>
						<!-- <a @click="handleEdit(record)" style="margin-right: 20px"
              >系统详情</a
            > -->
						<a @click="handleEdit(record)" style="margin-right: 20px" v-if="isDeveloper">修改</a>
						<!-- <a @click="handleEdit(record)" style="color: Red">删除</a> -->
					</span>
				</template>
			</template>
		</a-table>
		<a-pagination
			style="float: right; margin-top: 10px"
			v-model:current="page_no"
			v-model:pageSize="page_size"
			show-size-changer
			:total="total"
			:show-total="(total) => `总计 ${total} 条`"
			@change="handleChangePage"
		>
			<template v-slot:buildOptionText="props">
				<span>{{ props.value }}条/页</span>
			</template>
		</a-pagination>
	</a-card>
	<a-drawer width="640" placement="right" v-model:visible="dialogVisible" @close="changeDiagle" :closable="false">
		<p class="pStyleTitle">{{ edit ? "编辑系统" : "新增系统" }}</p>
		<a-form
			:model="authoritySystem"
			name="basic"
			:label-col="{ span: 4 }"
			:wrapper-col="{ span: 16 }"
			autocomplete="off"
			@finish="onFinish"
			@finishFailed="onFinishFailed"
		>
			<a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入名称' }]">
				<a-input v-model:value="authoritySystem.name" />
			</a-form-item>
			<a-form-item label="系统ID" name="name" :rules="[{ required: true, message: '请输入系统ID' }]">
				<a-input :disabled="edit" v-model:value="authoritySystem.code" />
			</a-form-item>
			<a-form-item label="描述" name="desc" :rules="[{ required: true, message: '请输入系统描述' }]">
				<a-input v-model:value="authoritySystem.desc" />
			</a-form-item>
			<a-form-item label="绑定SAAS应用" name="desc">
				<a-select v-model:value="merchantAppIdNew" placeholder="Please select a country">
					<a-select-option :value="item.id + '-' + item.name" v-for="item in appList" :key="item.id">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
				<a-button type="primary" html-type="submit">提交</a-button>
			</a-form-item>
		</a-form>
	</a-drawer>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs } from "vue"
// import axios from "@/common/axios";
import { useRouter } from "vue-router"
import useCopy from "@/hooks/useCopy"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
// import { message } from "ant-design-vue";
import { localGet, localSet } from "@/common/utils"
// import IdaasDescription from "@/components/IdaasDescription.vue";
import { message } from "ant-design-vue"
export default {
	name: "Application",
	components: {
		// IdaasDescription,
	},
	setup() {
		const multipleTable = ref(null)
		const router = useRouter()
		const store = useStore()
		const copy = useCopy
		const state = reactive({
			isDeveloper: "",
			loading: false,
			merchantAppId: "",
			tableData: [], // 数据列表
			tableDataAlert: [], // 数据列表
			selectList: [], // 选中项
			ouathDisable: false,
			columns: [
				{
					title: "系统名称",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "系统ID",
					dataIndex: "code",
					key: "code",
				},
				{
					title: "绑定应用名称",
					dataIndex: "merchantAppVOList",
					key: "merchantAppVOList",
				},

				{
					title: "系统状态",
					dataIndex: "status",
					key: "status",
				},
				{
					title: "系统描述",
					dataIndex: "desc",
					key: "desc",
				},
				{
					title: "操作",
					key: "action",
					width: 320,
				},
			],
		})
		onMounted(() => {
			state.isDeveloper = store.getters["user/merchantName"] == "GEEZ"
			getDataList()
		})
		const params = reactive({
			name: "",
			total: 0, // 总条数
			page_no: 1, // 当前页
			page_size: 10, // 分页大小
			old_page_size: 10, //存储请求时的pagesize
		})
		// 获取列表
		const getDataList = () => {
			// state.loading = true;
			const data = {
				...params,
				merchantId: store.getters["user/merchantId"],
			}
			delete data.total
			axios
				.post(api.authoritySystemList, data)
				.then((res) => {
					console.log("列表数据", res)
					state.tableData = res
					state.loading = false
					state.tableData.forEach((item) => {
						item.status = item.status === 1 ? true : false
					})
					params.total = res.length
					// params.page_no = res.page_no;
					// params.page_size = res.page_size;
					// params.old_page_size = res.page_size;
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		// 当前页 及当前页
		const handleChangePage = (page, pageSize) => {
			params.page_no = params.old_page_size === pageSize ? page : 1
			getDataList()
		}
		// 新增
		const handleAdd = () => {
			diagleDate.dialogVisible = true
			getMerchantAppList()
			formData.edit = false
			formData.authoritySystem = {}
			formData.merchantAppIdNew = ""
		}
		// 编辑
		const handleEdit = (id) => {
			console.log(id)
			console.log(router)
			getMerchantAppList()
			formData.edit = true
			formData.authoritySystem = id
			setTimeout(() => {
				formData.merchantAppIdNew =
					id.merchantAppVOList && id.merchantAppVOList.length > 0 ? id.merchantAppVOList[0].id + "-" + id.merchantAppVOList[0].name : ""
			})
			console.log(formData.merchantAppIdNew)
			diagleDate.dialogVisible = true
		}
		//关联系统弹框
		const appAlert = (value) => {
			if (!value.appClient.clientId) {
				message.warning("当前系统没有密钥，请点击详情生成密钥")
				return
			}
			state.merchantAppId = value.id
			let data = {
				merchantId: store.getters["user/merchantId"],
				merchantAppId: value.id,
				bindAppType: "LAZADA",
				targetAppIdBound: "",
				// targetAppId: 0,// 暂时没用
			}
			axios
				.post(api.merchantAppListBindabl, data)
				.then((res) => {
					console.log(res)
					state.tableDataAlert = res
					diagleDate.selectApp = true
				})
				.catch((err) => {
					console.log(err)
				})
		}

		// 详情模板 控制页面显示隐藏
		const diagleDate = reactive({
			dialogVisible: false,
			authAlert: localGet("authAlert") || "open",
			visibleSecret: false,
			secretConfirm: false,
			selectApp: false, //控制选择系统的弹框
			secret: { clientId: "", clientSecret: "" },
		})
		const closeAlert = () => {
			diagleDate.authAlert = "close"
			localSet("authAlert", "close")
		}
		const changeDiagle = () => {
			diagleDate.dialogVisible = false
		}
		const formData = reactive({
			authoritySystem: {
				name: "",
				id: "",
				code: "",
				desc: "",
				merchantId: store.getters["user/merchantId"],
				status: "",
			},
			merchantAppIdNew: "",
			edit: false,
			appList: [],
		})
		// 添加组织机构
		const onFinish = (value) => {
			console.log(value)
			formData.authoritySystem.merchantId = store.getters["user/merchantId"]
			axios
				.post(api.authoritySystemSave, {
					authoritySystem: formData.authoritySystem,
					merchantAppId: formData.merchantAppIdNew.indexOf("-") > -1 ? formData.merchantAppIdNew.indexOf("-")[0] : "",
				})
				.then((res) => {
					console.log(res)
					message.success(`${formData.edit ? "修改成功" : "添加成功"}`)
					getDataList()
					diagleDate.dialogVisible = false
				})
				.catch((err) => {
					console.log(err)
				})
		}
		const onFinishFailed = (value) => {
			console.log(value)
		}
		const managePage = (value, type) => {
			console.log(value, type)
			value.appList = value.appList ? JSON.stringify(value.appList) : value.appList
			value.merchantAppVOList = value.merchantAppVOList ? JSON.stringify(value.merchantAppVOList) : ""
			router.push({ name: type, query: value })
		}
		// 获取列表
		const getMerchantAppList = () => {
			const data = {
				merchantId: store.getters["user/merchantId"],
				ext: true,
				appCategory: "",
				page_no: 1,
				page_size: 1000,
			}
			axios.post(api.getMerchantAppList, data).then((res) => {
				console.log("getMerchantAppList", res)
				formData.appList = res.list
			})
		}

		const tableHeight = computed(() => window.innerHeight - (diagleDate.authAlert === "open" ? 460 : 300))
		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			...toRefs(formData),
			multipleTable,
			getDataList,
			handleAdd,
			handleEdit,
			getMerchantAppList,
			handleChangePage,
			closeAlert,
			changeDiagle,
			onFinish,
			onFinishFailed,
			copy,
			appAlert,
			managePage,
			tableHeight,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
	padding-bottom: 10px;
}
.a-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.cardBox {
	display: flex;
	justify-content: space-around;
	.ant-card-head-title {
		font-size: 12px;
	}
}
:deep(.ant-card) {
	width: 25% !important;
	height: 145px;
	font-size: 12px;
	margin: 0 10px;
	.ant-card-head {
		font-size: 12px;
		padding: 0 16px;
	}
	.ant-card-body {
		padding-bottom: 0;
		padding: 16px;
		.ant-btn {
			font-size: 12px;
		}
		.cardP {
			margin-bottom: 0;
			a {
				margin-right: 18px;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.cardContent {
			color: #909399;
			height: 40px;
		}
	}
}
.alertContent {
	font-size: 12px;
}
.pStyle {
	font-size: 12px;
	color: #657180;
}
.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
.secretStyle {
	font-weight: 900;
	margin-right: 20px;
}
.copy:hover {
	cursor: pointer;
}
</style>
