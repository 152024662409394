<template>
	<a-select
		v-model:value="code"
		show-search
		placeholder="input search text"
		:default-active-first-option="false"
		:show-arrow="false"
		:filter-option="false"
		:not-found-content="null"
		:options="options"
		@search="handleSearch"
		@change="handleChange"
	></a-select>
</template>
<script setup>
import { defineProps, defineExpose, ref, onMounted, defineEmits } from "vue"
// import { useI18n } from "vue-i18n/index";
const props = defineProps({
	appCode: {
		type: String,
		default: "",
	},
})

const emits = defineEmits(["change"])
const areaId = ref("")
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"

const store = useStore()
const options = ref([])

onMounted(() => {
	getList()
	code.value = props.appCode
})
const name = ref()
const handleSearch = (value) => {
	console.log(value)
	name.value = value
	getList()
}
const code = ref(undefined)
const handleChange = (value) => {
	console.log(value, options.value)
	code.value = value
	emits("change", value)
}
const getList = () => {
	options.value = []
	const data = {
		merchantId: store.getters["user/merchantId"],
		page_no: 1,
		page_size: 100,
		name: name.value,
	}
	axios.post(api.getAppList, data).then((res) => {
		console.log("getMerchantAppList", res.list)
		options.value = []
		res.list.forEach((i) => {
			options.value.push({
				label: i.code,
				value: i.code,
			})
		})
	})
}
const clear = () => {
	areaId.value = ""
}
defineExpose({
	clear,
})
</script>
