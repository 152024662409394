<template>
  <a-card class="guest-container">
    <a-alert
      message="授权管理"
      type="info"
      show-icon
      closable
      v-show="diagleDate.hostAlert === 'open'"
      @close="closeAlert"
      style="margin-bottom: 10px"
    >
      <template #icon><bulb-outlined style="margin-top: 20px" /></template>
      <template #description>
        <div class="alertContent">
          基于 RBAC 和 ABAC 的权限模型， 对系统自身和业务应用皆适用，
          左边是主体树（Subject）通常是人， 右边是客体树（Permission）,
          交叉形成权限组合（Privilege）
        </div>
      </template>
    </a-alert>
    <a-row style="width: 100%" :gutter="[16, 16]">
      <a-col :span="6">
        <a-card class="cardWidth">
          <a-tabs size="small" v-model:activeKey="state.leftActiveKey">
            <a-tab-pane key="user" tab="账户">
              <!-- <Account /> -->
              <div class="header">
                <a-input
                  style="margin-right: 10px"
                  v-model:value="userName"
                  placeholder="请输入名称进行搜索"
                  @keyup.enter="getAccountList()"
                />

                <a-button type="primary" @click="getAccountList()">
                  查询
                </a-button>
              </div>
              <div style="height: 600px; overflow-y: auto" class="sideMenu">
                <div
                  v-for="item in userList"
                  :key="item.id"
                  @click="setAccount(item)"
                  class="listUser"
                >
                  <div class="leftList">
                    <div class="line" v-show="item.active"></div>
                    {{ item.userName }}
                  </div>
                  <div><right-outlined /></div>
                </div>
              </div>
            </a-tab-pane>
            <!-- <a-tab-pane key="custom" tab="组织机构"> 按客体授权主体</a-tab-pane> -->
          </a-tabs>
        </a-card>
      </a-col>
      <a-col :span="18">
        <a-card class="cardWidth">
          <a-tabs
            size="small"
            v-model:activeKey="state.rightActiveKey"
            @change="changeTab"
          >
            <a-tab-pane key="auth" tab="授权到权限">
              <div class="authTreeStyle">
                <a-spin :spinning="treeLoading">
                  <a-row><a-col :span="8">
                      <p class="funClass">PC菜单权限</p>
                      <a-tree
                        v-model:expandedKeys="treeData.menuExpandedKeys"
                        v-model:checkedKeys="treeData.menuCheckedKeys"
                        @check="setMenuKeys"
                        checkable
                        :tree-data="menuTreeList"
                        :field-names="fieldNames"
                      ></a-tree>
                    </a-col>
                    <a-col :span="8">
                      <p class="funClass">功能权限</p>
                      <a-tree
                        v-model:expandedKeys="treeData.funExpandedKeys"
                        v-model:checkedKeys="treeData.funCheckedKeys"
                        @check="setFunKeys"
                        checkable
                        :tree-data="funTreeList"
                        :field-names="fieldNames"
                      ></a-tree>
                    </a-col>
                    
                    <a-col :span="8" v-if="pdaMenuTreeList.length">
                      <p class="funClass">PDA菜单权限</p>
                      <a-tree
                        v-model:expandedKeys="treeData.pdaMenuExpandedKeys"
                        v-model:checkedKeys="treeData.pdaMenuCheckedKeys"
                        @check="setPdaMenuKeys"
                        checkable
                        :tree-data="pdaMenuTreeList"
                        :field-names="fieldNames"
                      ></a-tree>
                    </a-col>
                  </a-row>
                </a-spin>
              </div>
            </a-tab-pane>
            <a-tab-pane key="role" tab="授权到角色">
              <div class="header">
                <a-input
                  style="width: 180px; margin: 0 15px"
                  v-model:value="state.name"
                  placeholder="请输入名称进行搜索"
                  @keyup.enter="getRoleList()"
                  allowClear
                />

                <a-button type="primary" @click="getRoleList()">查询</a-button>
              </div>
              <a-table
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                :loading="state.loading"
                :columns="state.columns"
                rowKey="id"
                :scroll="{ y: 500 }"
                :data-source="roleList"
                :pagination="false"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'status'">
                    <span>
                      <a-switch disabled v-model:checked="record.status" />
                    </span>
                  </template>
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>
          <template #actions>
            <a-button type="primary" @click="save">保存</a-button>
          </template>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script setup>
import { onActivated, ref } from "vue";
import { useRoute } from "vue-router";
import axios from "@/common/axios";
import api from "@/common/api/api";
import { useStore } from "vuex";

// import { message } from "ant-design-vue";
import { localGet, localSet } from "@/common/utils";
import { message } from "ant-design-vue";

const route = useRoute();
const store = useStore();
const state = ref({
  loading: false,
  merchantAppId: "",
  name: "",
  columns: [
    {
      title: "角色名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "角色ID",
      dataIndex: "code",
      key: "code",
      width: "100",
    },

    {
      title: "角色状态",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "角色描述",
      dataIndex: "note",
      key: "note",
    },
  ],
  leftActiveKey: "user",
  rightActiveKey: "auth",
});
onActivated(() => {
  // debugger;
  console.log(route);
  route.query.appList = route.query.appList
    ? JSON.parse(route.query.appList)
    : route.query.appList;
  // value.appList
  state.value.leftActiveKey = "user";
  state.value.rightActiveKey = "auth";
  getAccountList();
});
const userName = ref("");
const userList = ref([]);
const userId = ref("");
const treeLoading = ref(false);
//请求左侧账户列表
const getAccountList = () => {
  axios
    .post(api.getUserList, {
      mobile: "",
      merchantId: store.getters["user/merchantId"],
      userName: userName.value,
      total: 0,
      page_no: 1,
      page_size: 1000,
    })
    .then(res => {
      userList.value = res.list;
      userId.value = userList.value[0].id;
      setAccount(userList.value[0]);
      // getAuthList();
    });
};
const setAccount = value => {
  userList.value.forEach(item => {
    if (item.id == value.id) {
      userId.value = value.id;
      item.active = true;
      if (state.value.rightActiveKey == "auth") {
        getAuthList();
      } else {
        getRoleList();
      }
    } else {
      item.active = false;
    }
  });
};
const fieldNames = ref({
  children: "childrenList",
  title: "name",
});
const funTreeList = ref([]);
const menuTreeList = ref([]);
const pdaMenuTreeList = ref([]);
const addTreeKey = (value, type) => {
  value.forEach(item => {
    item.key = String(item.id);
    if (type) {
      if (item.type == "FUNC") {
        treeData.value.funCheckedKeys.push(item.key);
      } else if (item.terminal == "PDA") {
        treeData.value.pdaMenuCheckedKeys.push(item.key);
      } else {
        treeData.value.menuCheckedKeys.push(item.key);
      }
    } else {
      treeData.value.funCheckedKeys = [];
      treeData.value.menuCheckedKeys = [];
      treeData.value.pdaMenuCheckedKeys = [];
    }
    if (item.type == "FUNC") {
      treeData.value.funExpandedKeys.push(item.key);
    } else if (item.terminal == "PDA") {
      treeData.value.pdaMenuExpandedKeys.push(item.key);
    } else {
      treeData.value.menuExpandedKeys.push(item.key);
    }
    addTreeKey(item.childrenList, type);
  });
};
const funParentKeys = ref([]); //功能父节点
const menuParentKeys = ref([]); //
const pdaMenuParentKeys = ref([]); //
const getParentTreeKey = value => {
  value.forEach(item => {
    if (item.childrenList && item.childrenList.length > 0) {
      if (item.type == "FUNC") {
        funParentKeys.value.push(item.key);
      } else if (item.terminal == "PDA") {
        pdaMenuParentKeys.value.push(item.key);
      } else {
        menuParentKeys.value.push(item.key);
      }
      getParentTreeKey(item.childrenList);
    }
  });
};
// 获取树结构
const getAuthList = () => {
  treeLoading.value = true;
  axios
    .post(api.resourceList, {
      merchantId: store.getters["user/merchantId"],
      authSysCode: route.query.code,
      name: "",
      status: true,
      all: true, //是否需要全部权限
      resourceTypes: ["MENU", "FUNC"], //资源类型 MENU菜单 FUNC功能
      tree: true, //是否需要树形结构
      userId: "",
    })
    .then(res => {
      addTreeKey(res);
      getParentTreeKey(res);
      funTreeList.value = [];
      menuTreeList.value = [];
      pdaMenuTreeList.value = [];
      res.forEach(item => {
        if (item.type == "FUNC") {
          funTreeList.value.push(item);
        } else if (item.terminal == "PDA") {
          pdaMenuTreeList.value.push(item);
        } else {
          menuTreeList.value.push(item);
        }
      });

      getAuthCheckList();
    });
};
//获取已勾选的列表
const getAuthCheckList = () => {
  treeLoading.value = true;
  axios
    .post(api.resourceList, {
      merchantId: store.getters["user/merchantId"],
      authSysCode: route.query.code,
      name: "",
      status: true,
      all: false, //是否需要全部权限
      resourceTypes: ["MENU", "FUNC"], //资源类型 MENU菜单 FUNC功能
      tree: true, //是否需要树形结构
      userId: userId.value,
    })
    .then(res => {
      addTreeKey(res, true);
      console.dir(treeData.value.funCheckedKeys);
      console.dir(funParentKeys.value);
      treeData.value.funHalfKeys = [];
      treeData.value.menuHalfKeys = [];
      treeData.value.pdaMenuHalfKeys = [];
      let funArr = [];
      treeData.value.funCheckedKeys.forEach(item => {
        if (funParentKeys.value.indexOf(item) < 0) {
          funArr.push(item);
        } else {
          treeData.value.funHalfKeys.push(item);
        }
      });
      let menuArr = [];
      treeData.value.menuCheckedKeys.forEach(item => {
        if (menuParentKeys.value.indexOf(item) < 0) {
          menuArr.push(item);
        } else {
          treeData.value.menuHalfKeys.push(item);
        }
      });
      let pdaMenuArr = [];
      treeData.value.pdaMenuCheckedKeys.forEach(item => {
        if (pdaMenuParentKeys.value.indexOf(item) < 0) {
          pdaMenuArr.push(item);
        } else {
          treeData.value.pdaMenuHalfKeys.push(item);
        }
      });
      treeData.value.funCheckedKeys = funArr;
      treeData.value.menuCheckedKeys = menuArr;
      treeData.value.pdaMenuCheckedKeys = pdaMenuArr;
      treeLoading.value = false;
    });
};
const setFunKeys = (value, id) => {
  treeData.value.funHalfKeys = id.halfCheckedKeys;
};
const setMenuKeys = (value, id) => {
  treeData.value.menuHalfKeys = id.halfCheckedKeys;
};
const setPdaMenuKeys = (value, id) => {
  treeData.value.pdaMenuHalfKeys = id.halfCheckedKeys;
};

//角色功能
const roleList = ref([]);
const selectedRowKeys = ref([]);
const getRoleList = () => {
  state.value.loading = true;
  axios
    .post(api.roleList, {
      merchantId: store.getters["user/merchantId"],
      authSysCode: route.query.code,
      name: state.value.name,
      status: "",
      // userId: userId.value,
    })
    .then(res => {
      res.forEach(item => {
        item.id = String(item.id);
      });
      roleList.value = res;
      getRoleCheckList();
    })
    .catch(error => {
      console.log(error);
      state.value.loading = false;
    });
};
const getRoleCheckList = () => {
  axios
    .post(api.roleList, {
      merchantId: store.getters["user/merchantId"],
      authSysCode: route.query.code,
      name: state.value.name,
      status: "",
      userId: userId.value,
    })
    .then(res => {
      console.log(res);
      res.forEach(item => {
        item.id = String(item.id);
      });
      selectedRowKeys.value = res.map(item => String(item.id), []);
      state.value.loading = false;
    })
    .catch(error => {
      console.log(error);
      state.value.loading = false;
    });
};
const changeTab = value => {
  if (value == "auth") {
    setAccount({ id: userId.value });
  } else {
    getRoleList();
  }
};

const onSelectChange = value => {
  console.log(value);
  selectedRowKeys.value = value;
};
// 详情模板 控制页面显示隐藏
const diagleDate = ref({
  dialogVisible: false,
  appInfo: {},
  hostAlert: localGet("hostAlert") || "open",
});
//关闭 提示信息弹框
const closeAlert = () => {
  diagleDate.value.hostAlert = "close";
  localSet("hostAlert", "close");
};

const save = () => {
  let userAuthTyp = "";
  if (state.value.rightActiveKey == "auth") {
    userAuthTyp = "RESOURCE";
  } else if (state.value.rightActiveKey == "role") {
    userAuthTyp = "ROLE";
  }
  let data = {
    merchantId: store.getters["user/merchantId"],
    authSysCode: route.query.code,
    userAuthType: userAuthTyp,
    userResourceList: [],
    userRoleList: [],
    userId: userId.value,
  };
  // authTree.allChecked 为所有勾选的参数 通过子组件传入。
  console.log();
  if (state.value.rightActiveKey == "auth") {
    let allKeys = [
      ...treeData.value.funCheckedKeys,
      ...treeData.value.funHalfKeys,
      ...treeData.value.menuCheckedKeys,
      ...treeData.value.pdaMenuCheckedKeys,
      ...treeData.value.menuHalfKeys,
      ...treeData.value.pdaMenuHalfKeys,
    ];
    if (allKeys) {
      data.userResourceList = allKeys.map(item => {
        return {
          resourceId: item,
        };
      }, []);
    }
  } else {
    data.userRoleList = selectedRowKeys.value.map(item => {
      return {
        roleId: item,
      };
    }, []);
  }

  axios.post(api.userSaveUserAuth, data).then(() => {
    message.success("保存成功");
  });
};

const treeData = ref({
  funTreeList: [], //保存基本的树结构
  menuTreeList: [], //保存基本的树结构
  funExpandedKeys: [], //展开的key
  menuExpandedKeys: [], //Menu展开的key
  checkedKeys: [], //保存勾选的节点信息。
  funCheckedKeys: [],
  menuCheckedKeys: [], //保存勾选的菜单节点信息。
  funHalfKeys: [], //保存操作勾选是的父节点，用来保存的时候传参
  menuHalfKeys: [], //保存操作勾选是的父节点，用来保存的时候传参
  parentKeys: [], //保存拿到的父节点的数据，用来删除详情接口拿到的父节点
  userKeys: [], //存储用户默认勾选的
  pdaMenuTreeList: [],
  pdaMenuExpandedKeys: [],
  pdaMenuHalfKeys: [],
  pdaMenuCheckedKeys: [],
});
</script>
<style lang="scss" scoped>
.listUser {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 44px;
  padding-right: 10px;
  border: 1px solid ghostwhite;

  .leftList {
    display: flex;
    padding-left: 10px;
  }
  .line {
    font-weight: 900;
    background-color: #1890ff;
    margin-right: 10px;
    margin-left: -10px;
    width: 5px;
    height: 44px;
  }
}
.listUser:hover {
  cursor: pointer;
}
.header {
  display: flex;
  margin-bottom: 10px;
}
.authTreeStyle {
  height: 500px;
  overflow: auto;
}
.funClass {
  font-size: 16px;
  font-weight: 900;
}
::-webkit-scrollbar {
  width: 10px;
}
.sideMenu {
  height: 500px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track-piece {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c2;
  }
}
</style>
