<template>
	<a-card class="guest-container">
		<template #title>
			<a-alert message="角色管理" type="info" show-icon closable v-show="authAlert === 'open'" @close="closeAlert" style="margin-bottom: 10px">
				<template #icon><bulb-outlined style="margin-top: 20px" /></template>
				<template #description>
					<div class="alertContent">
						IDaaS 的权限系统支持角色授权模型（RBAC）。角色可以关联到一系列指定权限上，拥有角色的账号则即可拥有所有对应的权限。
						<br />
						管理员可以在这里为指定权限系统的角色进行新增、删除、编辑、关联权限等管理操作。
					</div>
				</template>
			</a-alert>
			<div class="header">
				<a-button @click="backUp" style="margin-right: 15px">返回</a-button>
				<a-button type="primary" @click="handleAdd">新增角色</a-button>
				<a-input style="width: 180px; margin: 0 15px" v-model:value="name" placeholder="请输入名称进行搜索" />

				<a-button type="primary" @click="getDataList">查询</a-button>
			</div>
		</template>
		<a-table :loading="loading" :columns="columns" rowKey="id" :scroll="{ y: 500 }" :data-source="tableData" :pagination="false">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'status'">
					<span>
						<a-popconfirm title="当前操作将修改角色状态，是否继续" ok-text="确认" cancel-text="取消" @confirm="confirm(record)" @cancel="cancel">
							<a-switch v-model:checked="record.status" />
						</a-popconfirm>
					</span>
				</template>
				<template v-if="column.key === 'action'">
					<span>
						<a @click="oauth(record, 'oauthDetail')" style="margin-right: 20px"> 授权到主体 </a>
						<a @click="handleEdit(record)" style="margin-right: 20px">编辑</a>
						<a @click="handleDelete(record)" style="color: Red">删除</a>
					</span>
				</template>
			</template>
		</a-table>
		<a-pagination
			style="float: right; margin-top: 10px"
			v-model:current="page_no"
			v-model:pageSize="page_size"
			show-size-changer
			:total="total"
			:show-total="(total) => `总计 ${total} 条`"
			@change="handleChangePage"
		>
			<template v-slot:buildOptionText="props">
				<span>{{ props.value }}条/页</span>
			</template>
		</a-pagination>
	</a-card>
	<a-drawer width="900" placement="right" v-model:visible="dialogVisible" @close="changeDiagle" :closable="false">
		<p class="pStyleTitle">{{ edit ? "编辑角色" : "新增角色" }}</p>
		<a-form
			:model="role"
			name="basic"
			:label-col="{ span: 4 }"
			:wrapper-col="{ span: 18 }"
			autocomplete="off"
			@finish="onFinish"
			@finishFailed="onFinishFailed"
		>
			<a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入名称' }]">
				<a-input v-model:value="role.name" />
			</a-form-item>
			<a-form-item label="角色code" name="name" :rules="[{ required: true, message: '请输入角色code' }]">
				<a-input v-model:value="role.code" />
			</a-form-item>
			<a-form-item label="描述" name="note" :rules="[{ required: true, message: '请输入角色描述' }]">
				<a-input v-model:value="role.note" />
			</a-form-item>
			<a-form-item label="资源列表" name="roleResourceList">
				<a-spin :spinning="treeLoading">
					<a-row class="authTreeStyle">
						<a-col :span="8">
							<p class="funClass">PC菜单权限</p>
							<a-tree
								ref="treeRef2"
								:tree-data="menuTreeList"
								checkable
								:field-names="fieldNames"
								v-model:expandedKeys="expandedKeys"
								v-model:checkedKeys="menuCheckedKeys"
								@check="changeMenuSelect"
							/>
						</a-col>
						<a-col :span="8">
							<p class="funClass">功能权限</p>
							<a-tree
								ref="treeRef1"
								:tree-data="funcTreeList"
								checkable
								:field-names="fieldNames"
								v-model:expandedKeys="expandedKeys"
								v-model:checkedKeys="funcCheckedKeys"
								@check="changeFuncSelect"
							/>
						</a-col>

						<a-col :span="8">
							<p class="funClass">PDA菜单权限</p>
							<a-tree
								ref="treeRef2"
								:tree-data="pdaMenuTreeList"
								checkable
								:field-names="fieldNames"
								v-model:expandedKeys="expandedKeys"
								v-model:checkedKeys="pdaMenuCheckedKeys"
								@check="changePdaMenuSelect"
							/>
						</a-col>
					</a-row>
				</a-spin>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
				<a-button type="primary" html-type="submit">保存</a-button>
			</a-form-item>
		</a-form>
	</a-drawer>
</template>

<script>
import { onActivated, reactive, ref, toRefs } from "vue"
import { useRouter, useRoute } from "vue-router"
import useCopy from "@/hooks/useCopy"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { localGet, localSet } from "@/common/utils"
import { message, Modal } from "ant-design-vue"
export default {
	name: "Application",
	setup() {
		const multipleTable = ref(null)
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const copy = useCopy
		const state = reactive({
			loading: false,
			merchantAppId: "",
			tableData: [], // 数据列表
			tableDataAlert: [], // 数据列表
			selectList: [], // 选中项
			ouathDisable: false,
			columns: [
				{
					title: "角色名称",
					dataIndex: "name",
					key: "name",
				},
				{
					title: "角色ID",
					dataIndex: "code",
					key: "code",
					width: "100",
				},

				{
					title: "角色状态",
					dataIndex: "status",
					key: "status",
				},
				{
					title: "角色描述",
					dataIndex: "note",
					key: "note",
				},
				{
					title: "操作",
					key: "action",
					width: "220px",
				},
			],
			treeLoading: false,
		})
		onActivated(() => {
			getDataList()
		})
		const params = reactive({
			name: "",
			status: true,
			total: 0, // 总条数
			page_no: 1, // 当前页
			page_size: 10, // 分页大小
			old_page_size: 10, //存储请求时的pagesize
		})
		// 获取列表
		const getDataList = () => {
			state.loading = true
			const data = {
				...params,
				merchantId: store.getters["user/merchantId"],
				authSysCode: route.query.code,
			}
			delete data.total
			axios
				.post(api.roleList, data)
				.then((res) => {
					state.tableData = res
					state.loading = false
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		// 当前页 及当前页
		const handleChangePage = (page, pageSize) => {
			params.page_no = params.old_page_size === pageSize ? page : 1
			getDataList()
		}
		// 新增
		const handleAdd = async () => {
			state.treeLoading = true
			diagleDate.dialogVisible = true
			formData.edit = false
			formData.role = {
				name: "",
				id: "",
				code: "",
				note: "",
				merchantId: store.getters["user/merchantId"],
				status: true,
				authSysCode: route.query.code,
				roleResourceList: [],
			}
			treeData.funcCheckedKeys = []
			treeData.menuCheckedKeys = []
			treeData.pdaMenuCheckedKeys = []
			await getTreeList()
			state.treeLoading = false
		}
		// 编辑
		const handleEdit = async (record) => {
			diagleDate.dialogVisible = true
			formData.edit = true
			record.status = true
			formData.role = record
			state.treeLoading = true
			let data = {
				roleId: record.id,
				merchantId: record.id,
			}
			await getTreeList()
			let res = await axios.post(api.roleDetail, data)
			addKey(res.role.roleResourceList, true)
			let funcCheckKeys = [],
				menuCheckKeys = [],
				pdaMenuCheckKeys = [],
				funcHalfKeys = [],
				menuHalfKeys = [],
				pdaMenuHalfKeys = []
			res.role.roleResourceList.forEach((item) => {
				if (item.type === "FUNC") {
					funcCheckKeys.push(item.key)
				} else if (item.terminal == "PDA") {
					pdaMenuCheckKeys.push(item.key)
				} else {
					menuCheckKeys.push(item.key)
				}
			})
			console.log("treeData.parentKeys: ", treeData.parentKeys)
			treeData.callbackCheckedKeys = [...funcCheckKeys, ...menuCheckKeys, ...pdaMenuCheckKeys]
			funcCheckKeys.forEach((item, index) => {
				if (treeData.parentKeys.includes(item)) {
					funcHalfKeys.push(item)
					funcCheckKeys.splice(index, 1, "") //直接splice 会影响原遍历数组，所以需要置为空
				}
			})
			menuCheckKeys.forEach((item, index) => {
				if (treeData.parentKeys.includes(item)) {
					menuHalfKeys.push(item)
					menuCheckKeys.splice(index, 1, "") //直接splice 会影响原遍历数组，所以需要置为空
				}
			})
			pdaMenuCheckKeys.forEach((item, index) => {
				if (treeData.parentKeys.includes(item)) {
					pdaMenuHalfKeys.push(item)
					pdaMenuCheckKeys.splice(index, 1, "") //直接splice 会影响原遍历数组，所以需要置为空
				}
			})
			funcCheckKeys = funcCheckKeys.filter((item) => item) //再清空数据
			menuCheckKeys = menuCheckKeys.filter((item) => item)
			pdaMenuCheckKeys = pdaMenuCheckKeys.filter((item) => item)
			treeData.funcCheckedKeys = [...funcCheckKeys]
			treeData.menuCheckedKeys = [...menuCheckKeys]
			treeData.pdaMenuCheckedKeys = [...pdaMenuCheckKeys]
			treeData.funcHalfKeys = [...funcHalfKeys]
			treeData.menuHalfKeys = [...menuHalfKeys]
			treeData.pdaMenuHalfKeys = [...pdaMenuHalfKeys]
			state.treeLoading = false
		}

		//删除角色
		const handleDelete = (record) => {
			Modal.confirm({
				title: "温馨提示",
				content: `确定要删除"${record.name}"角色吗？`,
				okText: "确认",
				cancelText: "取消",
				onOk: async () => {
					await axios.post(api.deleteRole, {
						roleId: record.id,
					})
					message.success("删除成功")
					getDataList()
				},
			})
		}

		// 详情模板 控制页面显示隐藏
		const diagleDate = reactive({
			dialogVisible: false,
			authAlert: localGet("authAlert") || "open",
			visibleSecret: false,
			secretConfirm: false,
			selectApp: false, //控制选择系统的弹框
			secret: { clientId: "", clientSecret: "" },
		})
		const closeAlert = () => {
			diagleDate.authAlert = "close"
			localSet("authAlert", "close")
		}
		const changeDiagle = () => {
			diagleDate.dialogVisible = false
		}
		const formData = reactive({
			role: {
				name: "",
				id: "",
				code: "",
				note: "",
				merchantId: store.getters["user/merchantId"],
				status: true,
				roleResourceList: [],
			},
			edit: false,
		})
		// 添加组织机构
		const onFinish = () => {
			formData.role.merchantId = store.getters["user/merchantId"]
			formData.role.roleResourceList = []
			// 后端需要传子节点和父节点，halfkeys保存了父节点，所以需要合并传参。
			let funcKeys = [...treeData.funcCheckedKeys, ...treeData.funcHalfKeys]
			let menuKeys = [...treeData.menuCheckedKeys, ...treeData.menuHalfKeys]
			let pdaMenuKeys = [...treeData.pdaMenuCheckedKeys, ...treeData.pdaMenuHalfKeys]
			let allKeys = treeData.callbackCheckedKeys.length > 0 ? treeData.callbackCheckedKeys : [...funcKeys, ...menuKeys, ...pdaMenuKeys]
			allKeys.forEach((item) => {
				if (!item) return
				formData.role.roleResourceList.push({
					resourceId: item.split("-")[0],
				})
			})
			axios
				.post(api.roleSave, {
					role: formData.role,
				})
				.then(() => {
					message.success("保存成功")
					getDataList()
					diagleDate.dialogVisible = false
				})
				.catch((err) => {
					console.log(err)
				})
		}
		const onFinishFailed = (value) => {
			console.log(value)
		}

		const treeData = reactive({
			menuTreeList: [], //保存基本的树结构
			pdaMenuTreeList: [], //保存基本的树结构
			funcTreeList: [], //保存基本的树结构
			callbackCheckedKeys: [], //保存请求回来的勾选信息，在操作勾选时清空
			expandedKeys: [],
			// checkedKeys: [], //保存勾选的节点信息。
			funcCheckedKeys: [],
			menuCheckedKeys: [],
			pdaMenuCheckedKeys: [],
			pdaMenuHalfKeys: [],
			funcHalfKeys: [], //保存操作勾选是的父节点，用来保存的时候传参
			menuHalfKeys: [], //保存操作勾选是的父节点，用来保存的时候传参
			padMenuHalfKeys: [], //保存操作勾选是的父节点，用来保存的时候传参
			parentKeys: [], //保存拿到的父节点的数据，用来删除详情接口拿到的父节点
			nowKeys: {
				id: "",
				name: "",
			},
			fieldNames: {
				children: "childrenList",
				title: "name",
			},
		})
		const getTreeList = async () => {
			// if (treeData.funcTreeList.length) return
			let data = {
				name: "",
				status: true,
				all: true, //是否需要全部权限
				resourceTypes: ["MENU", "FUNC"], //资源类型 MENU菜单 FUNC功能
				tree: true, //是否需要树形结构
				userId: "",
				merchantId: store.getters["user/merchantId"],
				authSysCode: route.query.code,
			}
			let res = await axios.post(api.resourceList, data)
			addKey(res)
			let treeList = res,
				menuTreeList = [],
				pdaMenuTreeList = [],
				funcTreeList = []
			treeList.forEach((item) => {
				if (item.type === "FUNC") {
					funcTreeList.push(item)
				} else if (item.terminal == "PDA") {
					pdaMenuTreeList.push(item)
				} else {
					menuTreeList.push(item)
				}
			})
			treeData.parentKeys = []
			treeData.funcTreeList = funcTreeList
			treeData.menuTreeList = menuTreeList
			treeData.pdaMenuTreeList = pdaMenuTreeList
			getParentKeys(treeList)
		}
		const getParentKeys = (value) => {
			let arr = []
			value.forEach((item) => {
				if (item.children && item.children.length > 0) {
					getParentKeys(item.children)
					treeData.parentKeys.push(item.key)
				}
			})
			return arr
		}
		// 为展开项加key值
		const addKey = (value, type) => {
			value.forEach((item) => {
				item.key = item.id + "-" + item.status
				if (!type) {
					treeData.expandedKeys.push(item.key)
				}
				if (item.childrenList.length > 0) {
					item.children = item.childrenList
					addKey(item.childrenList)
				}
			})
		}
		const changeFuncSelect = (value, id) => {
			// console.log(value, id);
			treeData.callbackCheckedKeys = []
			treeData.funcCheckedKeys = value
			treeData.funcHalfKeys = id.halfCheckedKeys
		}
		const changeMenuSelect = (value, id) => {
			// console.log(value, id);
			treeData.callbackCheckedKeys = []
			treeData.menuCheckedKeys = value
			treeData.menuHalfKeys = id.halfCheckedKeys
		}
		const changePdaMenuSelect = (value, id) => {
			// console.log(value, id);
			treeData.callbackCheckedKeys = []
			treeData.pdaMenuCheckedKeys = value
			treeData.pdaMenuHalfKeys = id.halfCheckedKeys
		}
		const confirm = (value) => {
			value.status = !value.status
			formData.role = value
			onFinish()
		}
		const cancel = () => {
			getDataList()
		}

		const oauth = (value, type) => {
			router.push({ name: type, query: route.query })
		}
		const backUp = () => {
			router.back()
		}
		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			...toRefs(formData),
			...toRefs(treeData),
			multipleTable,
			changeFuncSelect,
			changeMenuSelect,
			changePdaMenuSelect,
			getDataList,
			handleAdd,
			handleEdit,
			handleDelete,
			handleChangePage,
			closeAlert,
			changeDiagle,
			onFinish,
			onFinishFailed,
			copy,
			getTreeList,
			confirm,
			cancel,
			oauth,
			backUp,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
	padding-bottom: 10px;
}
.a-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.cardBox {
	display: flex;
	justify-content: space-around;
	.ant-card-head-title {
		font-size: 12px;
	}
}
:deep(.ant-tree .ant-tree-node-content-wrapper) {
	white-space: nowrap;
}
:deep(.ant-card) {
	width: 25% !important;
	height: 145px;
	font-size: 12px;
	margin: 0 10px;
	.ant-card-head {
		font-size: 12px;
		padding: 0 16px;
	}
	.ant-card-body {
		padding-bottom: 0;
		padding: 16px;
		.ant-btn {
			font-size: 12px;
		}
		.cardP {
			margin-bottom: 0;
			a {
				margin-right: 18px;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.cardContent {
			color: #909399;
			height: 40px;
		}
	}
}
.alertContent {
	font-size: 12px;
}
.pStyle {
	font-size: 12px;
	color: #657180;
}
.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
.secretStyle {
	font-weight: 900;
	margin-right: 20px;
}
.copy:hover {
	cursor: pointer;
}
.authTreeStyle {
	height: 500px;
	overflow-y: auto;
}
.funClass {
	font-size: 16px;
	font-weight: 900;
}
</style>
